import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107')
];

export const server_loads = [0,2,11,4,20,10,21];

export const dictionary = {
		"/(app)": [24,[2]],
		"/(spa)/(brokerage)/accounts-payable": [46,[11,12]],
		"/(spa)/(brokerage)/accounts-receivable": [47,[11,12]],
		"/(spa)/(brokerage)/brokerage": [48,[11,12,13]],
		"/(spa)/(brokerage)/brokerage/settings": [49,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/branding": [50,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/carrier-agreement": [51,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/commission": [52,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/dat-integration": [53,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/highway-integration": [54,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/leads": [55,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/macropoint-integration": [56,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/mcp-integration": [57,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/quickbooks-online": [58,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/rmis-integration": [59,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/trucker-tools-integration": [60,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings/truckstop-integration": [61,[11,12,13,14]],
		"/(spa)/(brokerage)/brokerage/users": [62,[11,12,13]],
		"/(spa)/(brokerage)/carriers": [63,[11,12]],
		"/(spa)/(brokerage)/carriers/search": [70,[11,12]],
		"/(spa)/(brokerage)/carriers/[carrierId]": [64,[11,12,15]],
		"/(spa)/(brokerage)/carriers/[carrierId]/activity-log": [65,[11,12,15]],
		"/(spa)/(brokerage)/carriers/[carrierId]/compliance": [66,[11,12,15]],
		"/(spa)/(brokerage)/carriers/[carrierId]/contacts": [67,[11,12,15]],
		"/(spa)/(brokerage)/carriers/[carrierId]/load-history": [68,[11,12,15]],
		"/(spa)/(brokerage)/carriers/[carrierId]/payment-info": [69,[11,12,15]],
		"/(spa)/(brokerage)/commissions": [71,[11,12]],
		"/(public)/(with-layout)/confirmed": [34,[,7],[6]],
		"/(spa)/(brokerage)/customer-management": [72,[11,12,16]],
		"/(spa)/(brokerage)/customer-management/customers": [73,[11,12,16,17]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]": [74,[11,12,18]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/activity-log": [75,[11,12,18]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/contacts": [76,[11,12,18]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/edi-log": [77,[11,12,18]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/lead-info": [78,[11,12,18]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/order-history": [~79,[11,12,18]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/settings/charge-codes": [80,[11,12,18]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/settings/references": [81,[11,12,18]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/shipping-locations": [82,[11,12,18]],
		"/(spa)/(brokerage)/dashboard": [83,[11,12]],
		"/(app)/documents": [25,[2]],
		"/(public)/(with-layout)/forgot-password": [35,[,7],[6]],
		"/(spa)/internal/brokerages": [100,[11,22]],
		"/(spa)/internal/brokerages/[organizationId]": [101,[11,22,23]],
		"/(spa)/internal/brokerages/[organizationId]/activity-log": [102,[11,22,23]],
		"/(spa)/internal/brokerages/[organizationId]/users": [103,[11,22,23]],
		"/(spa)/internal/brokerages/[organizationId]/users/[userId]": [104,[11,22,23]],
		"/(spa)/internal/charge-codes": [105,[11,22]],
		"/(spa)/internal/references": [106,[11,22]],
		"/(spa)/internal/special-requirements": [107,[11,22]],
		"/(app)/loads": [26,[2,3]],
		"/(app)/loads/[loadId]": [27,[2,3,4]],
		"/(app)/location": [28,[2]],
		"/(public)/(with-layout)/login": [36,[,7],[6]],
		"/(app)/logout": [~29,[2]],
		"/(public)/oauth2/truckstop": [38,[],[6]],
		"/(app)/orders": [~30,[2,5]],
		"/(app)/orders/drafts": [~31,[2,5]],
		"/(app)/orders/drafts/[orderId]": [~32,[2,5]],
		"/(app)/orders/old": [33,[2,5]],
		"/(public)/(with-layout)/password-reset/[username]": [~37,[,7],[6]],
		"/(public)/payment-complete": [~39,[],[6]],
		"/(public)/pricing": [40,[,8],[6]],
		"/(spa)/(brokerage)/quoting/shipper-quotes": [84,[11,12,19]],
		"/(spa)/(brokerage)/quoting/shipper-rates": [85,[11,12,19]],
		"/(spa)/(brokerage)/shipments": [~86,[11,12]],
		"/(spa)/(brokerage)/shipments/[shipmentId]": [87,[11,12,20]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/activity-log": [88,[11,12,20]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/check-calls": [89,[11,12,20]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/details": [90,[11,12,20]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/documents": [91,[11,12,20]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/edi-log": [92,[11,12,20]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/financials": [93,[11,12,20]],
		"/(public)/sign/[dsrId]": [~41,[,9],[6]],
		"/(public)/tracking/[shipmentId]": [42,[,10],[6]],
		"/(public)/tracking/[shipmentId]/check-calls": [43,[,10],[6]],
		"/(public)/tracking/[shipmentId]/documents": [44,[,10],[6]],
		"/(public)/tracking/[shipmentId]/edi-log": [45,[,10],[6]],
		"/(spa)/(brokerage)/vendors": [94,[11,12]],
		"/(spa)/(brokerage)/vendors/[vendorId]": [95,[11,12,21]],
		"/(spa)/(brokerage)/vendors/[vendorId]/activity-log": [96,[11,12,21]],
		"/(spa)/(brokerage)/vendors/[vendorId]/contacts": [97,[11,12,21]],
		"/(spa)/(brokerage)/vendors/[vendorId]/documents": [98,[11,12,21]],
		"/(spa)/(brokerage)/vendors/[vendorId]/locations": [99,[11,12,21]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';